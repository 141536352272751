import io from "socket.io-client";
import api from "../../api/api";

let url = "https://padelove.store"
let socket;

/**
 * Inicializa la conexión del socket para el usuario.
 * @param {Object} userInfo Información del usuario, incluyendo su ID.
 */
export const initiateSocket = (userInfo,onNotification) => {
  try {
    socket = io(url, {
      withCredentials: true,
      auth: {
        userId: userInfo.id, 
      },
    });
    console.log("Usuario conectado");

    socket.on("shopApproval", (data) => {
      const { token } = data;
      if (token) {      
        localStorage.setItem("token", token); 
        window.location.reload(); 
      }
    });

socket.on("shopRequest", (data)=> {
  const { token } = data;
  if (token) {      
    localStorage.setItem("token", token); 
    window.location.reload(); 
  }

})

socket.on("nuevaNotificacion", (data) => {
  console.log("Nueva notificación recibida:", data);
  if (userInfo.onNotification) {
    userInfo.onNotification(data); // Llama al callback que recibiste desde el componente Navbar
  }
});



  } catch (error) {
    console.error("Error al conectar socket:", error);
    
  }

};

/**
 * Enviar datos de compra.
 * @param {Object} data Datos de la compra.
 */
export const sendBuy = (data) => {
  if (socket) socket.emit("compra", data);
};

/**
 * Escuchar la respuesta de una compra.
 * @param {Function} cb Callback para manejar la respuesta de la compra.
 */
export const receivedBuy = (cb) => {
  if (socket) socket.on("venta", cb);
};

/**
 * Desconectar el socket.
 */
export const disconnectSocket = () => {
  if (socket) socket.disconnect();
  console.log("Usuario desconectado");
};
